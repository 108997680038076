import React, { useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import axios from 'axios';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';


const EditProperty = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const propertyDetails = location.state?.propertyDetails || '';

    const [formData, setFormData] = useState({
        id: propertyDetails.id || '',
        property_type: propertyDetails.property_type || '',
        villaName: propertyDetails.villaName || '',
        villaHeadline: propertyDetails.villaHeadline || '',
        villaContent: propertyDetails.villaContent || '',
        max_adults: propertyDetails.max_adults || '',
        max_child: propertyDetails.max_child || '',
        max_bedrooms: propertyDetails.max_bedrooms || '',
        max_bathrooms: propertyDetails.max_bathrooms || '',
        location: propertyDetails.nearby_beach || '',
        price: propertyDetails.price || '', // Default price
        default_price: propertyDetails.default_price || '',
        taxes: propertyDetails.taxes || '18%',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const updateProperty = async () => {
        try {
            const response = await axios.put(API_URL + `/updateProperty?id=${formData.id}`, {
                ...formData,
                prices,
            });

            if (response.status === 200) {
                window.alert('Villa updated successfully!');

                axios.get('https://sohamvillas.com/web-backend/updatePricesForCustomChanges');

                navigate('/PropertyDatabase');
            } else {
                console.error('Error updating villa');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [prices, setPrices] = useState({});
    const [currentPrice, setCurrentPrice] = useState('');
    const [customPrices, setCustomPrices] = useState({});
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 7),
            key: 'selection'
        }
    ]);

    // Handle selection of new date ranges
    const handleSelect = (ranges) => {
        setState([ranges.selection]);
    };

    // Modify fetchCustomPrices function
useEffect(() => {
    const fetchCustomPrices = async () => {
        try {
            const response = await axios.get(`${API_URL}/custom-price`);
            if (response.status === 200) {
                // Create a map that associates ID with its corresponding prices
                const priceMap = {};
                response.data.forEach(item => {
                    const formattedDate = moment(item.date).utc().format('YYYY-MM-DD');
                    if (!priceMap[item.id]) {
                        priceMap[item.id] = {}; // Initialize if it doesn't exist
                    }
                    priceMap[item.id][formattedDate] = item.price; // Associate date with price for the specific ID
                });

                // If you want to save custom prices for a specific property ID
                const propertyID = formData.id; // Assuming you're dealing with one property
                setCustomPrices(priceMap[propertyID] || {}); // Set custom prices for the specific property
            } else {
                console.error('Error fetching custom prices');
            }
        } catch (error) {
            console.error('Error fetching data from custom-price API:', error);
        }
    };

    fetchCustomPrices();
}, [formData.id]); // Ensure to fetch whenever the property ID changes

    const handleSetPrice = async () => {
        const start = moment(state[0].startDate);
        const end = moment(state[0].endDate);
        let tempPrices = { ...prices };
    
        for (let m = start; m.diff(end, 'days') <= 0; m.add(1, 'days')) {
            const dateStr = m.format('YYYY-MM-DD');
            // Update price for each date in the range
            tempPrices[dateStr] = currentPrice || customPrices[dateStr] || formData.price;
        }
    
        setPrices(tempPrices);
    
        // Call to update the prices in the backend
        await customupdatePrice(tempPrices);
    
        // Optional additional API call to update prices for custom changes
        axios.get('https://sohamvillas.com/web-backend/updatePricesForCustomChanges');
    
        // Clear the current price input
        setCurrentPrice('');
    };

    const customupdatePrice = async (updatedPrices) => {
        const data = {
            defaultPrice: formData.default_price,
            // Send each date with its price to the backend
            customPrices: Object.entries(updatedPrices).map(([date, price]) => ({
                id: formData.id, // Include the property ID to associate the prices
                date,           // Date for which the price is being set
                price           // The price for that date
            }))
        };
    
        try {
            const response = await axios.post(`${API_URL}/updatePrice`, data);
    
            if (response.status === 200) {
                window.alert('Villa price updated successfully!');
                navigate('/PropertyDatabase');
            } else {
                console.error('Error updating property');
            }
        } catch (error) {
            console.error(error);
        }
    };
    
// Update dayContentRenderer to use the custom prices for the specific ID
const dayContentRenderer = (day) => {
    const dateString = moment(day).utc().format('YYYY-MM-DD');
    const customPrice = customPrices[dateString]; // Get custom price for the specific date
    const displayPrice = customPrice !== undefined ? customPrice : formData.price; // Fallback to the default price

    return (
        <div style={{ position: 'relative', textAlign: 'center', height: '100%' }}>
            <span>{moment(day).date()}</span>
            <div style={{
                fontSize: '10.5px',
                color: customPrice ? 'green' : '#5e5e5d',
                position: 'absolute',
                bottom: '7px',
                left: '-15px',
                right: '0',
            }}>
                Rs.{displayPrice}
            </div>
        </div>
    );
};

    const [isPopup_PriceCalendar, setPopup_PriceCalendar] = useState(false);

    const openPopup_PriceCalendar = () => {
        setPopup_PriceCalendar(true);
    };

    const closePopup_PriceCalendar = () => {
        setPopup_PriceCalendar(false);
    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>
                <p className='mb-[2%] font-semibold'>Edit Property</p>

                <div className='grid grid-cols-1 lg:grid-cols-3 items-center'>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Category</p>
                        <select style={{ appearance: 'none' }} className='bg-white border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='property_type' value={formData.property_type} onChange={handleInputChange}>
                            <option value='' disabled hidden>Select</option>
                            <option value='Villa'>Villa</option>
                            <option value='Apartment'>Apartment</option>
                        </select>
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Name</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaName' value={formData.villaName} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Headline</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaHeadline' value={formData.villaHeadline} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Property Contetnt</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='villaContent' value={formData.villaContent} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Location</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='location' value={formData.location} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Default Price/ Night</p>
                        <input
                            type="number"
                            className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md'
                            name='default_price'
                            value={formData.default_price}
                            onChange={handleInputChange}
                            placeholder='Enter default price'
                        />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Set Custom Price for Selected Dates</p>
                        <button className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' onClick={openPopup_PriceCalendar}>
                            Open Calendar
                        </button>

                        {isPopup_PriceCalendar && (
                            <>
                                <div className="overlay_PriceCalendar" onClick={closePopup_PriceCalendar}></div>
                                <div className="popup_form_PriceCalendar">
                                    <span className="close_PriceCalendar" onClick={closePopup_PriceCalendar}>&times;</span>
                                    <p>Set Custom Price for Selected Dates</p>

                                    <form>
                                        <div className='overflow-x-auto overflow-y-auto'>
                                            <DateRange
                                                ranges={state}
                                                onChange={handleSelect}
                                                showDateDisplay={true}
                                                moveRangeOnFirstSelection={false}
                                                dayContentRenderer={dayContentRenderer}
                                                rangeColors={['#bab5ad']}
                                            />
                                        </div>

                                        <div className='flex justify-end mt-4'>
                                            <input
                                                type="number"
                                                value={currentPrice}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    if (value >= 0 || value === '') {
                                                        setCurrentPrice(value);
                                                    }
                                                }}
                                                placeholder="Set Price"
                                                className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-auto rounded-lg outline-none focus:shadow-md'
                                            />
                                            <div>
                                                <button
                                                    type='button'
                                                    className='done_btn ml-2'
                                                    onClick={handleSetPrice}
                                                >
                                                    Set&nbsp;Price
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Adults</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_adults' value={formData.max_adults} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Child</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_child' value={formData.max_child} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bedrooms</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_bedrooms' value={formData.max_bedrooms} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Max Bathroom</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='max_bathrooms' value={formData.max_bathrooms} onChange={handleInputChange} />
                    </div>

                    <div className='m-[2%] lg:mx-[5%]'>
                        <p className='text-[12px] md:text-sm 2xl:text-lg leading-4'>Taxes</p>
                        <input type="text" className='border-[2px] p-[1%] px-[2%] h-[40px] 2xl:h-[55px] w-full rounded-lg outline-none focus:shadow-md' name='taxes' value={formData.taxes} onChange={handleInputChange} />
                    </div>
                </div>

                <div className='flex my-[3%] lg:my-[1%] ml-[2%]'>
                    <button className="cancel_btn" onClick={() => navigate('/PropertyDatabase')}>Cancel</button>

                    <button className="done_btn" onClick={() => updateProperty()}>Done</button>
                </div>

            </div>
        </div>
    );
};

export default EditProperty;