import React, { useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import { HiOutlineInformationCircle } from "react-icons/hi2";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
// import { BsFilterLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../Config';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { TbMailShare } from "react-icons/tb";


const ManualBooking = () => {

    const navigate = useNavigate();
    const [bookings, setBookings] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [checkInDate, setCheckInDate] = useState('');
    const [checkOutDate, setCheckOutDate] = useState('');

    const handleAddManualBooking = () => {
        navigate('/AddManualBooking');
    };


    const [isHandle_Delete, setHandle_Delete] = useState(false);

    // const openForm_Delete = () => {
    //     setHandle_Delete(true);
    // };

    const closeForm_Delete = () => {
        setHandle_Delete(false);
    };

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(API_URL + '/reservationDetails', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        role: 'admin'
                    }),
                });
                let data = await response.json();

                const today = new Date().toISOString().split('T')[0];
                const filteredBookings = data.filter(booking => booking.checkIn >= today);
                setBookings(filteredBookings);
                localStorage.setItem('bookings', JSON.stringify(filteredBookings));
            } catch (error) {
                console.error('Error fetching bookings:', error);
            }
        };

        fetchBookings();
    }, []);

    const tableTitles = ['Booking ID', 'Full Name', 'Email', 'Property', 'Check In', 'Check Out', 'Amount', 'Status',
        'Booking Date', 'Portal', 'Action'];

    const getStatusCellStyle = (status) => {
        switch (status) {
            case 'Confirmed':
                return { color: '#36993F', fontWeight: 500 };
            case 'Partial':
                return { color: '#F3894E', fontWeight: 500 };
            case 'Cancelled':
                return { color: '#C12929', fontWeight: 500 };
            case 'Pending':
                return { color: '#C0BB34', fontWeight: 500 };
            case 'Direct Bank':
                return { color: '#2D949A', fontWeight: 500 };
            default:
                return {};
        }
    };

    const isWithinDateRange = (date, startDate, endDate) => {
        if (!startDate && !endDate) return true;
        if (!startDate) return new Date(date) <= new Date(endDate);
        if (!endDate) return new Date(date) >= new Date(startDate);
        return new Date(date) >= new Date(startDate) && new Date(date) <= new Date(endDate);
    };

    const filteredBookings = bookings.filter(i => {
        const searchQueryLower = searchQuery.toLowerCase();
        i.name = i.name ? i.name.toString() : '';
        i.id = i.id ? i.id.toString() : '';
        i.villaName = i.villaName ? i.villaName.toString() : '';
        i.total = i.total ? i.total.toString() : '';
        i.email = i.email ? i.email.toString() : '';
        i.checkIn = i.checkIn ? i.checkIn.toString() : '';
        i.checkOut = i.checkOut ? i.checkOut.toString() : '';
        i.payment_status = i.payment_status ? i.payment_status.toString() : '';
        i.booking_portal = i.booking_portal ? i.booking_portal.toString() : '';

        return (searchQuery === '' ||
            i.name.toLowerCase().includes(searchQueryLower) ||
            i.id.toString().includes(searchQueryLower) ||
            i.villaName.toLowerCase().includes(searchQueryLower) ||
            i.total.toString().includes(searchQueryLower) ||
            i.email.toLowerCase().includes(searchQueryLower) ||
            i.booking_portal.toLowerCase().includes(searchQueryLower) ||
            i.payment_status.toLowerCase().includes(searchQueryLower)) &&
            (isWithinDateRange(i.checkIn, checkInDate, checkOutDate) || isWithinDateRange(i.checkOut, checkInDate, checkOutDate))
    }
    );
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredBookings.length > 0 ? filteredBookings.slice(indexOfFirstRow, indexOfLastRow) : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
        const totalPages = Math.ceil(totalRows / rowsPerPage);

        return (
            <nav className='flex justify-end my-[2%]'>
                <ul className='flex items-center'>
                    {currentPage > 1 && (
                        <li className='mx-1'>
                            <button
                                onClick={() => paginate(currentPage - 1)}
                                className='p-1 bg-gray-200 text-xs rounded-full'>
                                <MdKeyboardArrowLeft />
                            </button>
                        </li>
                    )}
                    <li className='mx-1'>
                        <span className='px-2 py-2 text-xs'>
                            {currentPage} / {totalPages}
                        </span>
                    </li>
                    {currentPage < totalPages && (
                        <li className='mx-1'>
                            <button
                                onClick={() => paginate(currentPage + 1)}
                                className='p-1 bg-gray-200 text-xs rounded-full'>
                                <MdKeyboardArrowRight />
                            </button>
                        </li>
                    )}
                </ul>
            </nav>
        );
    };
    const handleEditBooking = (row) => {
        navigate('/EditBooking', { state: { villaDetails: row } });
    }

    const handleEmail = (id) => {
        const numericId = Number(id); // Convert ID to a number    
        fetch(API_URL + '/send-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: numericId }), // Send the ID in the request body
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                alert('Email sent successfully!'); // Alert on successful email sending
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('Failed to send email.'); // Alert on error
            });
    };

    const [fileName, setFileName] = useState('Total Revenue');
    const handleExport = () => {
        const userInput = window.prompt('Enter file name:', 'Total Revenue');
        if (userInput) {
            setFileName(userInput.trim());
            handleExportConfirm();
        }
    };

    const handleExportConfirm = () => {
        const exportData = currentRows;

        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });

        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        saveAs(blob, `${fileName}.xlsx`);
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    return (
        <div className='text-[14px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-6 md:leading-8 lg:leading-7 2xl:leading-10'>
            <Menu />
            <div className='lg:ml-[8%] p-[3%] md:p-[2%]'>

                <div className='lg:hidden'>
                    <div className='flex justify-end items-center mb-[1%] mx-[3%] md:mx-[1%]'>
                        <div className='mr-2'>
                            <p className='text-xs'>Check-In</p>
                            <input type="date" className='text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer' value={checkInDate} onChange={(e) => setCheckInDate(e.target.value)} />
                        </div>

                        <div className=''>
                            <p className='text-xs'>Check-Out</p>
                            <input type="date" className='text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer' value={checkOutDate} onChange={(e) => setCheckOutDate(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col lg:flex-row justify-between lg:items-center mx-[3%] md:mx-[1%] mb-[2%]'>
                    <div className='flex items-center justify-between'>
                        <p className='font-semibold'>Custom Booking</p>

                        {/* <button className='lg:hidden flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#444444] hover:text-white' onClick={handleAddManualBooking}>
                            Add&nbsp;Booking
                        </button> */}
                    </div>

                    <div className='flex my-[2%] lg:my-0'>
                        <div className="mr-2 p-2 px-5 overflow-hidden w-[50px] h-[35px] hover:w-[250px] bg-white shadow-sm rounded-lg flex group items-center hover:duration-300 duration-300 cursor-pointer">
                            <div className="flex items-center justify-center fill-[#444444]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="Isolation_Mode"
                                    data-name="Isolation Mode"
                                    viewBox="0 0 24 24"
                                    width="12"
                                    height="12"
                                >
                                    <path d="M18.9,16.776A10.539,10.539,0,1,0,16.776,18.9l5.1,5.1L24,21.88ZM10.5,18A7.5,7.5,0,1,1,18,10.5,7.507,7.507,0,0,1,10.5,18Z"></path>
                                </svg>
                            </div>
                            <input
                                type="text"
                                className="outline-none text-xs bg-transparent w-full text-gray-500 font-normal px-4"
                                placeholder='Search here...'
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>

                        <div className='hidden lg:block mx-2 -mt-3.5'>
                            <p className='text-xs'>Check-In</p>
                            <input
                                type="date"
                                className='text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer'
                                value={checkInDate}
                                onChange={(e) => setCheckInDate(e.target.value)} />
                        </div>

                        <div className='hidden lg:block mr-1 -mt-3.5'>
                            <p className='text-xs'>Check-Out</p>
                            <input
                                type="date"
                                className='text-xs p-[1%] px-[3%] h-[30px] bg-white w-24 lg:w-full rounded-lg outline-none border border-[#444444] cursor-pointer'
                                value={checkOutDate}
                                onChange={(e) => setCheckOutDate(e.target.value)} />
                        </div>

                        <button className="download_btn ml-2" onClick={() => handleExport()}>
                            <svg className="svgIcon" viewBox="0 0 384 512" height="1rem" xmlns="http://www.w3.org/2000/svg"><path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>
                            <span className="icon2"></span>
                            <span className="tooltip">Download</span>
                        </button>

                        {/* <button className='flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#2F65B8] hover:text-white'> 
                            Filter<BsFilterLeft className='ml-1 text-[14px]' />
                        </button> */}

                        {/* <div className='hidden lg:block'> */}
                        <button className='flex items-center ml-2 h-[35px] border-[1px] border-[#444444] rounded-lg px-2 text-[#444444] text-[12px] bg-white hover:bg-[#877D6F] hover:text-white' onClick={handleAddManualBooking}>
                            Add&nbsp;Booking
                        </button>
                        {/* </div> */}
                    </div>
                </div>

                <div className='mx-[2%] md:mx-[1%] overflow-x-auto'>
                    <table className='w-full border-collapse text-[#7C7C7C] text-[14px] 2xl:text-[18px]'>
                        <thead className='bg-[#E7ECEB]'>
                            <tr>
                                {tableTitles.map((tableTitle, index) => (
                                    <th key={index} className='border-b p-2 text-left'>
                                        {tableTitle}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((row, index) => (
                                <tr key={index} className={`${index % 2 === 0 ? 'bg-[#F7F7F7]' : 'bg-white'}`}>
                                    <td className='p-2'>{row.id}</td>
                                    <td className='p-2'>{row.name}</td>
                                    <td className='p-2'>{row.email}</td>
                                    <td className='p-2'>{row.villaName}</td>
                                    <td className='p-2'>
                                        {new Date(row.checkIn).toLocaleDateString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}
                                    </td>
                                    <td className='p-2'>
                                        {new Date(row.checkOut).toLocaleDateString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric'
                                        })}
                                    </td>
                                    <td className='p-2'>{row.total}</td>
                                    <td className='p-2' style={getStatusCellStyle(row.status)}>{row.payment_status}</td>
                                    <td className='p-2'>
                                        {new Date(row.createdAt).toLocaleDateString('en-IN', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        })}
                                    </td>
                                    <td className='p-2'>{row.booking_portal}</td>
                                    <td className='p-2 flex justify-between items-center align-items-center'>
                                        <button onClick={() => handleEditBooking(row)} className="text-[19px] ml-[2%] hover:scale-110">
                                            <HiOutlineInformationCircle />
                                        </button>
                                        {row.email && (
                                            <button
                                                onClick={() => handleEmail(row.id)} 
                                                className="text-[19px] ml-[20%] hover:scale-110"
                                            >
                                                <TbMailShare />
                                            </button>
                                        )}

                                        {/* <button className="text-[19px] ml-[20%] hover:scale-110 hover:text-red-700" onClick={openForm_Delete}>
                                            <MdOutlineDeleteForever /></button> */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    rowsPerPage={rowsPerPage}
                    totalRows={filteredBookings.length}
                    paginate={paginate}
                    currentPage={currentPage}
                />


                {isHandle_Delete && (
                    <>
                        <div className="overlay_Delete" onClick={closeForm_Delete}></div>
                        <div className="popup_form_Delete">
                            <span className="close_Delete" onClick={closeForm_Delete}>&times;</span>
                            <form>
                                <div>
                                    <p className='text-white mb-[5%]'>Are you sure you want to delete this?</p>
                                    <button className='bg-white text-[#877D6F] rounded-lg p-[1%] w-[20%] hover:scale-90 mr-[2%]'>Cancel</button>
                                    <button className='bg-[#877D6F] text-white rounded-lg p-[1%] w-[20%] hover:scale-90'>Yes</button>
                                </div>
                            </form>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default ManualBooking;